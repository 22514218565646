import { graphql } from 'gatsby'
import * as React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/layout'
import Seo from '../components/seo'

const NotFoundPage = (props) => {
  const { data } = props
  const { strapiInstitution: institution } = data
  return (
    <Layout
      page={institution}
      indexHeader={true}
      header={institution.header}
    >
      <Seo title="404: Not found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  data: PropTypes.object.isRequired
}

export default NotFoundPage

export const indexPageQuery = graphql`
  query {
    strapiInstitution {
      name
      id
      navbar {
        name
        icon {
          code
          type
        }
        organizational_unit {
          id
          name
          slug
        }
        organizationalUnits: organizationalUnit {
          id
          name
          icon {
            code
            type
          }
          organizational_unit {
            name
            slug
            id
          }
        }
        page {
          name
          slug
          id
        }
      }
    }
  }
`
